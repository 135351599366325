import { BrowserModule } from "@angular/platform-browser";
import { Component, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


// Bootstrap DataTable
import { DataTablesModule } from "angular-datatables";
import { ToastrModule } from "ngx-toastr";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { JwtModule } from "@auth0/angular-jwt";
import { SharedModule } from "primeng/api";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import {LoaderInterceptorService} from "src/app/shared/services/loader-interceptor.service";
import { SpinnerComponent } from './spinner/spinner.component';


// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

export function tokenGetter() {
  return localStorage.getItem("token");
}

@NgModule({
  declarations: [AppComponent, SpinnerComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgSelectModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [
          "localhost:44367",
          "app.samudrafoods.com",
          "www.app.samudrafoods.com",
          "webapp.samudrafoods.com",
          "web.samudrafoods.com",
          "trini.thexpressclub.com",
          "prdapi.liveexe.com"       
        ],
      },
    }),
    SharedModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    //NgxMatSelectSearchModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true
    // },
    {
      // provide: "API_BASE_URL",useValue: "https://WebApp.samudrafoods.com/",
     //LiveSF 
     // provide: "API_BASE_URL", useValue: "https://app.samudrafoods.com/",
     
     
     
     //SF PRD
   provide: "API_BASE_URL", useValue: "https://prdapi.liveexe.com/",
   //provide: "API_BASE_URL", useValue: "https://localhost:44367/",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

