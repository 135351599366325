import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
{ path: '', redirectTo: 'home', pathMatch: 'full' },
 
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule)
  },
  
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule)
  },
 
  {
    path: 'samundra',
    data: { breadcrumb: 'Samundra Food' },
    loadChildren: () => import('./state/state.module').then(m => m.StateModule)
  },
 
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: { breadcrumb: "404 Error" }
  },
  {
    path: '**',
    redirectTo: '/404',
    data: { breadcrumb: "404 Error" }
  },
 { path: "login", loadChildren: () => import(`./login/login.module`).then((m) => m.LoginModule) },
  { path: "error", loadChildren: () => import(`./errorpages/errorpages.module`).then((m) => m.ErrorpagesModule) },
  { path: '**', redirectTo: '/login'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
